import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  filesUrl = 'http://files.retroidhandhelds.com';
  listPath = '/wallpaper_list.php';
  wallpaperList: any = [];

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.getWallpapers().subscribe((data) => {
      this.wallpaperList = data;
    });
  }

  getWallpapers(): Observable<any> {
    return this.http.get(this.filesUrl + this.listPath);
  }

  goToImage(item: any): void {
    window
      .open(this.filesUrl + '/wallpapers/' + item.wallpaper, '_blank')
      .focus();
  }
}
