<div class="wallpaper-list">
  <div
    class="wallpaper"
    *ngFor="let item of wallpaperList | orderBy: ['-datetime']"
    tabindex="0"
    (click)="goToImage(item)"
    [style.background-image]="
      'url(' + filesUrl + '/wallpapers/' + item.wallpaper + ')'
    "
  >
    <div class="upload-info" (click)="$event.stopPropagation()">
      Author: <span class="author">{{ item.author }}</span
      ><br />
      Source: {{ item.source }}<br />
      Posted on {{ item.datetime | date }}<br />
    </div>
  </div>
</div>
